<template>
  <div>
    <Modal class="overflow-y-hidden relative" height="430px" width="500px" :modalVisible="isModalAssignClass" @close="hideModalAssignClass()" id="assignClass">
      <template slot="modal-content">
        <h1 class="text-2xl font-bold mb-3 mt-5">Assign to New Class</h1>
        <div class="mb-5">
          <label class="text-xs pl-3 font-medium text-neutral-500 text-left block mb-1">Program</label>
          <Dropdown :options="programOptions" optionLabel="name" v-model="selectedProgram" :default="selectedProgram" placeholder="Select program" />
        </div>
        <div class="relative mb-5">
          <label class="text-xs pl-3 font-medium text-neutral-500 text-left block mb-1">Class <span v-if="!selectedProgram" class="inline-block text-neutral-300">(Program required)</span></label>
          <div class="relative">
            <TextField :disabled="!selectedProgram" borderEnabled withIcon v-model="classKeyword" @input="searchClass()" placeholder="Search Class..." />
            <div class="absolute left-3 top-1/2 transform -translate-y-1/2">
              <Search />
            </div>
          </div>
          <div class="absolute text-left left-0 py-2 max-h-40 overflow-y-auto rounded-lg w-full bg-neutral-500 bg-opacity-90 z-10" v-if="isSearching">
            <template v-if="classList.length > 0 && !isFetching">
              <div @click="selectClass(item)" class="py-1 px-3 text-white cursor-pointer hover:bg-yellow-primary hover:text-white font-normal last:pb-0" v-for="item in classList" :key="item.id">
                {{ item.nama }}
              </div>
            </template>
            <template v-if="classList.length === 0 && !isFetching">
              <p class="py-1 px-3 text-white font-normal text-center">Class is not found.</p>
            </template>
          </div>
        </div>
        <div class="mb-8 absolute" style="width: 90.5%">
          <label class="text-xs pl-3 font-medium text-neutral-500 text-left block mb-1">Payment Method<span v-if="!selectedClass" class="inline-block text-neutral-300">(Class required)</span></label>
          <Dropdown :disabled="!selectedClass"
                    :key="paymentMethodListOptions.length"
                    :options="paymentMethodListOptions"
                    v-model="selectedPaymentMethod"
                    :default="selectedPaymentMethod"
                    optionLabel="name"
                    placeholder="Choose your payment method"
                    @input="selectPaymentMethodDropdown"
                  />
        </div>
        <div class="flex justify-center items-center pt-24">
          <Button :disabled="!canAssign" class="mr-5" buttonText="Assign to Class" type="secondary" @action="assignToClass()" />
          <Button buttonText="Cancel" type="tertiary" @action="hideModalAssignClass()" />
        </div>
      </template>
    </Modal>
    <div class="mb-6 flex justify-start items-center">
      <router-link to="/crm/student" class="flex justify-start items-center">
        <ArrowForward direction="left" width="25" height="25" />
      </router-link>
      <h1 class="text-3xl font-bold ml-3 hover:no-underline">Student Profile</h1>
    </div>
    <div v-if="isLoading" class="loading-page">
      <vue-simple-spinner size="large" message="Fetching Data ..."></vue-simple-spinner>
    </div>
    <div v-else :class="selectedTab === 'Detail' ? 'heightContent' : studentClassList.length > 0 ? 'min-h-screen' : 'heightContent'">
      <div class="tab flex justify-between items-end pl-4">
        <div class="flex gap-2">
          <div @click="selectTab('Detail')" class="text-sm px-5 py-2 rounded-tr-lg rounded-tl-lg cursor-pointer" :class="[selectedTab === 'Detail' ? 'font-bold bg-white' : 'bg-neutral-50']">
            Detail
          </div>
          <div @click="selectTab('Classes')" class="text-sm px-5 py-2 rounded-tr-lg rounded-tl-lg cursor-pointer" :class="[selectedTab === 'Classes' ? 'font-bold bg-white' : 'bg-neutral-50']">
            Classes
          </div>
        </div>
      </div>
      <div class="bg-white p-4 shadow-small rounded-lg card">
        <template v-if="selectedTab === 'Detail'">
          <div class="px-2 flex-1 flex-shrink-0">
            <p class="font-semibold mb-4 flex gap-1 items-center border-b border-neutral-50 py-1">
              <span class="-ml-1"><Label color="yellow" /></span>Profile Detail
            </p>
            <div class="mb-6">
              <div class="mb-3">
                <p class="text-xs font-semibold text-neutral-400">Full Name:</p>
                <p class="font-bold">{{ studentName }}</p>
              </div>
              <div class="mb-3">
                <p class="text-xs font-semibold text-neutral-400">Email:</p>
                <p class="font-bold">{{ studentEmail }}</p>
              </div>
              <div class="mb-3">
                <p class="text-xs font-semibold text-neutral-400">Phone Number:</p>
                <p class="font-bold">{{ studentPhoneNumber }}</p>
              </div>
              <div class="mb-3">
                <p class="text-xs font-semibold text-neutral-400">Motivation:</p>
                <p class="font-bold">{{ studentMotivation }}</p>
              </div>
            </div>
          </div>
        </template>
        <template v-else-if="selectedTab === 'Classes'">
          <div class="px-2 flex-1 flex-shrink-0">
            <div class="flex justify-between border-b border-neutral-50 pb-2 mb-4">
              <p class="font-semibold flex gap-1 items-center">
                <span class="-ml-1"><Label color="yellow" /></span>Classes
              </p>
              <div>
                <Button buttonText="Assign to Class" size="full" @action="showModalAssignClass" />
              </div>
            </div>
            <div class="mb-6">
              <vue-good-table
                v-if="studentClassList.length > 0"
                mode="remote"
                :pagination-options="{
                  enabled: true,
                  mode: 'pages',
                  perPage: size,
                  position: 'bottom',
                  perPageDropdown: [5, 10],
                  dropdownAllowAll: false,
                  nextLabel: 'next',
                  prevLabel: 'prev',
                  rowsPerPageLabel: 'Rows per page',
                  ofLabel: 'of',
                  pageLabel: 'page',
                  allLabel: 'All',
                  setCurrentPage: pageTable
                }"
                :totalRows="totalRows"
                :rows="studentClassList"
                :columns="tableHeader"
                :sort-options="{
                  enabled: false
                }"
                @on-page-change="onPageChange"
                @on-per-page-change="onSizeChange"
              >
                <template slot="table-row" slot-scope="props">
                  <div v-if="props.column.field == 'action'" class="flex flex-col gap-2">
                    <Button buttonText="Detail" type="tertiary" size="full" @action="toDetail(props.row.status, props.row.id)" />
                  </div>
                  <div v-else :class="props.column.field === 'progress' ? 'text-center' : ''">
                    {{ props.row[props.column.field] }}
                  </div>
                </template>
              </vue-good-table>
              <div v-else class="border rounded-lg h-64 flex justify-center items-center">
                <div class="bg-pink">
                  <p class="text-center text-neutral-400">No data available</p>
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import { mapActions } from 'vuex'
// import { showVueToast } from '@/utils'
export default {
  name: 'StudentProfile',
  components: {
    Modal: () => import(/* webpackChunkName: "Modal" */ '@/components/Modal/Modal'),
    TextField: () => import(/* webpackChunkName: "TextField" */ '@/components/Form/TextField'),
    Search: () => import(/* webpackChunkName: "Icons" */ '@/components/Icons/Search'),
    Dropdown: () => import(/* webpackChunkName: "Dropdown" */ '@/components/Dropdown/Dropdown'),
    ArrowForward: () => import(/* webpackChunkName: "icon" */ '@/components/Icons/ArrowForward'),
    Button: () => import(/* webpackChunkName: "Button" */ '@/components/Button/Button'),
    Label: () => import(/* webpackChunkName: "Icons" */ '@/components/Icons/Label')
  },
  data: function () {
    return {
      detailTab: false,
      classesTab: false,
      isLoading: true,
      sizeChange: false,
      isModalAssignClass: false,
      isFetching: false,
      isSearching: false,
      selectedProgram: null,
      selectedClass: null,
      selectedPaymentMethod: null,
      debounce: null,
      studentName: null,
      studentEmail: null,
      studentPhoneNumber: null,
      studentMotivation: null,
      selectedTab: null,
      startDate: null,
      endDate: null,
      keyword: null,
      classKeyword: '',
      classList: [],
      programOptions: [],
      studentClassList: [],
      paymentMethodListOptions: [{ code: 'UPFRONT_CASH', name: 'Upfront Cash' },
        { code: 'PAY_AS_YOU_GO', name: 'Pay as you go' },
        { code: 'STUDENT_LOAN', name: 'Student Loan' },
        { code: 'ISA', name: 'ISA' }],
      size: 10,
      currentPage: 0,
      totalRows: 0
    }
  },
  computed: {
    customerId() {
      return localStorage.getItem('client')
    },
    studentId() {
      return this.$route.params.id
    },
    tableHeader() {
      return [
        { field: 'name', label: 'Name' },
        { field: 'program', label: 'Program' },
        { field: 'status', label: 'Status' },
        { field: 'progress', label: 'Progress' },
        { field: 'lastUpdate', label: 'Last Update' },
        { field: 'action', label: 'Action' }
      ]
    },
    pageTable() {
      return this.currentPage + 1
    },
    isDefaultFilter() {
      return this.startDate || this.endDate ? true : false
    },
    canAssign() {
      return this.selectedProgram && this.selectedClass && this.selectedPaymentMethod
    }
  },
  watch: {
    selectedTab: function () {
      if (this.selectedTab === 'Detail' && !this.detailTab) {
        this.detailTab = true
        this.getStudentDetail()
      } else if (this.selectedTab === 'Classes' && !this.classesTab) {
        this.classesTab = true
        this.getPrograms()
        this.getStudentClasses()
      }
    }
  },
  mounted() {
    this.selectedTab = 'Detail'
  },
  methods: {
    ...mapActions('common', ['showLoading', 'hideLoading']),
    ...mapActions('student', ['GET_CRM_STUDENT_BY_ID', 'GET_CRM_STUDENT_CLASSES', 'ASSIGN_CRM_STUDENT']),
    ...mapActions('kelas', ['GET_LIST_PROGRAM', 'GET_LIST_KELAS']),
    selectTab(tab) {
      this.selectedTab = tab
    },
    selectPaymentMethodDropdown(paymentMethod) {
    },
    statusFriendly(status) {
      if (status) {
        const arrayStatus = status.split(' ')
        let newArrayStatus = []
        for (let i = 0; i < arrayStatus.length; i++) {
          const subArrayStatus = arrayStatus[i].split('_')
          if (subArrayStatus.length > 1) {
            let subNewArrayStatus = []
            for (let j = 0; j < subArrayStatus.length; j++) {
              newArrayStatus.push(`${subArrayStatus[j][0].toUpperCase()}${subArrayStatus[j].substr(1).toLowerCase()}`)
            }
          } else {
            newArrayStatus.push(`${arrayStatus[i][0].toUpperCase()}${arrayStatus[i].substr(1).toLowerCase()}`)
          }
        }
        return newArrayStatus.join(' ')
      } else {
        return '-'
      }
    },
    showModalAssignClass() {
      this.isModalAssignClass = true
    },
    hideModalAssignClass() {
      this.selectedProgram = this.selectedClass = this.selectedPaymentMethod = null
      this.isModalAssignClass = false
    },
    getPrograms() {
      this.GET_LIST_PROGRAM({}).then((res) => {        
        this.programOptions = res.data.data
        
      })
    },
    searchClass() {
      if (this.classKeyword !== '') {
        clearTimeout(this.debounce)
        this.debounce = setTimeout(() => {
          if (this.classKeyword !== '') {
            this.getClassList()
          }
        }, 300)
      } else {
        this.isSearching = false
      }
    },
    getClassList() {
      this.isFetching = true
      this.GET_LIST_KELAS({
        customerId: this.customerId,
        params: queryString.stringify({
          limit: 300,
          sort: 'name',
          program_code: this.selectedProgram.code,
          name: this.classKeyword
        })
      })
        .then((res) => {
          this.classList = res.data.data
          this.isSearching = true
          this.isFetching = false
        })
        .catch(function () {})
    },
    selectClass(selectedClass) {
      this.selectedClass = selectedClass
      this.classKeyword = selectedClass.nama
      this.isSearching = false
    },
    assignToClass() {
      this.showLoading()
      this.ASSIGN_CRM_STUDENT({
        id: this.studentId,
        payload: {
          coreClassId: this.selectedClass.id,
          paymentMethod: this.selectedPaymentMethod.code
        }
      })
        .then(() => {
          this.showVueToast('Assign to class successfully!', 'success', 2000)
          this.hideModalAssignClass()
          this.getStudentClasses()
        })
        .catch((res) => {
          if (res.errors) {
            this.showVueToast(`${res.errors.error}!`, 'error', 2000)
          } else {
            this.showVueToast('Failed to assign class, please try again later!', 'error', 2000)
          }
        })
      setTimeout(() => {
        this.hideLoading()
      }, 2500)
    },
    filterOrSearch() {
      this.currentPage = 0
      this.getStudentClasses()
    },
    clearFilter() {
      this.startDate = this.endDate = null
      this.currentPage = 0
      this.getStudentClasses()
    },
    clearSearch() {
      this.keyword = null
      this.currentPage = 0
      this.getStudentClasses()
    },
    toDetail(status, id) {
      this.$router.push(`${this.studentId}/${status.toLowerCase() === 'completed' ? '2' : status.toLowerCase() === 'registering' ? '1' : '0'}/${id}`)
    },
    onPageChange(params) {
      this.currentPage = params.currentPage - 1
      this.getStudentClasses()
    },
    onSizeChange(params) {
      if (!this.sizeChange) {
        this.sizeChange = true
        this.size = params.currentPerPage
        this.currentPage = 0
        this.getStudentClasses()
      }
    },
    getStudentDetail() {
      this.showLoading()
      this.GET_CRM_STUDENT_BY_ID({
        id: this.studentId
      }).then((res) => {
        this.studentName = res.data.data.name ? res.data.data.name : '-'
        this.studentEmail = res.data.data.email ? res.data.data.email : '-'
        this.studentPhoneNumber = res.data.data.phoneNumber ? res.data.data.phoneNumber : '-'
        this.studentMotivation = res.data.data.motivation ? res.data.data.motivation : '-'
        setTimeout(() => {
          this.isLoading = false
          this.hideLoading()
        }, 1000)
      })
    },
    getStudentClasses() {
      this.showLoading()
      this.GET_CRM_STUDENT_CLASSES({
        id: this.studentId,
        params: {
          customerId: this.customerId,
          page: this.currentPage,
          size: this.size,
          sortBy: 'updatedDate',
          direction: 'ASC',
          lastUpdatedStart: this.startDate ? `${moment(this.startDate).format('YYYY-MM-DD')}T00:00:00.000` : '',
          lastUpdatedEnd: this.endDate ? `${moment(this.endDate).format('YYYY-MM-DD')}T23:59:59.999` : '',
          q: this.keyword ? this.keyword : ''
        }
      }).then((res) => {
        this.totalRows = res.data.data.totalRows
        this.size = res.data.data.size
        this.currentPage = res.data.data.currentPage
        this.studentClassList = []
        res.data.data.data.forEach((studentClass) => {
          this.studentClassList.push({
            id: studentClass.id,
            name: studentClass.className,
            program: studentClass.programName,
            status: this.statusFriendly(studentClass.status),
            progress: `${studentClass.progressPercentage}%`,
            lastUpdate: moment(studentClass.updatedDate).format('DD MMM YYYY HH:mm')
          })
        })
        setTimeout(() => {
          this.isLoading = this.sizeChange = false
          this.hideLoading()
        }, 1000)
      })
    },
    showVueToast(text, status, duration) {
      this.$toasted.show(text, {
        position: 'top-center',
        type: status,
        duration,
        theme: 'bubble',
        singleton: true
      })
    }
  }
}
</script>
<style scoped lang="scss">
.heightContent {
  height: calc(100vh - 210px);
}
.card {
  &::v-deep .vgt-table thead th {
    white-space: nowrap;
  }
}
</style>
